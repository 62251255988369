@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Kodchasan:wght@300;400;500;600;700&family=Kumbh+Sans:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&family=Roboto+Flex:opsz,wght@8..144,300;8..144,400;8..144,500;8..144,600;8..144,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}