.flip-card {
  background-color: transparent;
  perspective: 1000px;
  cursor: pointer;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.7s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}

.glowSideLeft {
  background-color: rgba(255, 255, 255, 0);
  box-shadow: 120px 80px 35px 55px rgb(255, 255, 255);
  top: -90px;
  left: -140px;
}
.glowSideRight {
  background-color: #ffffff00;
  box-shadow: 120px 80px 35px 55px white;
  top: -74px;
  right: 100px;
}

.gradientGray {
  background: linear-gradient(
    133.73deg,
    rgba(0, 0, 0, 0.5) -34.7%,
    rgba(217, 217, 217, 0.5) 15.01%,
    rgba(124, 124, 124, 0.5) 60.03%,
    rgba(212, 212, 212, 0.5) 115.37%,
    rgba(203, 203, 203, 0.5) 145.39%
  );
}

.gradientYellow {
  background: linear-gradient(
    133.73deg,
    #c3a247 -34.7%,
    #feeab1 15.01%,
    #d7a928 60.03%,
    #d4d4d4 115.37%,
    #cbcbcb 145.39%
  );
}
