.card1 {
  background-image: url("assets/svg/Cards.svg");
  transform: translate(-50%, -50%);
  z-index: 1;
}

.bg-home-rectangle {
  background: radial-gradient(
    38.63% 47.24% at 65.24% 61.37%,
    #302b63 0%,
    #0f0c29 100%
  );
}
